import { gql } from '@apollo/client';

export const LOGOUT_USER = gql`
  mutation Logout {
    logout {
      message
    }
  }
`;

export const REFRESH_TOKEN = gql`
  query refreshToken($data: RefreshTokenInput!) {
    refreshToken(data: $data) {
      token
      user {
        id
        email
        profileImage
        name
        firstName
        lastName
      }
    }
  }
`;

export const GET_CURRENT_USER = gql`
  query currentUser {
    currentUser {
      id
      isPasswordSet
      email
      firstName
      lastName
      phoneNumber
      state
      city
      companyName
      roles
      profession
      profileImage
      isActive
      lastLogin
      pinCode
      address
      unreadNotifications
    }
  }
`;

export const VALIDATE_TOKEN = gql`
  query ValidateToken($data: ValidateTokenInput!) {
    validateToken(data: $data) {
      isValid
    }
  }
`;
