/* ROUTERS  */
export const ROUTES = {
  MAIN: '/',
  LOGOUT: '/logout',
  SIGNUP: '/signup',
  LOGIN: '/login',
  DASHBOARD: '/dashboard',
  SET_PASSWORD: '/set-password',
  RESET_PASSWORD: '/reset-password',
  FORGET_PASSWORD: '/forgot-password',
  VERIFY_EMAIL: '/verify-email',
  RESET: '/reset-password',
  VERIFY: '/verify',
  AUTHENTICATION: '/authentication',
  USERS: '/user',
  TEMPLATE_CATEGORY: '/template-category',
  TEMPLATE: '/template',
  ADD_TEMPLATE: '/template/add-template',
  EDIT_TEMPLATE: '/template/edit/:id',
  USER_DETAIL: '/user/:id',
  ADD_USERS: '/user/add-user',
  EDIT_USER: '/user/edit/:id',
  PROFILE: '/profile',
  ACCESS_DENIED: '/access-denied',
  SUBSCRIPTION_PLANS: '/subscription-plans',
  JUDGES: '/judges',
  ADD_JUDGE: '/judges/create',
  EDIT_JUDGE: '/judges/edit/',
  ASSESSES: '/assesses',
  ADD_ASSESSES: '/assesses/create',
  EDIT_ASSESSES: '/assesses/edit',
  PAYMENT_DISTRIBUTION: '/payment-distribution',
  CREATE: '/create',
  EDIT: '/edit/:id',
  JUDGEMENTS: '/judgements',
  ADD_APPEALS: '/appeals/create',
  EDIT_APPEALS: '/appeals/edit/',
  CONSULTATION_CALLS: '/consultation-calls',
  TRANSACTION_LOGS: '/transaction-logs',
  ADD_TRANSACTION: '/transaction-logs/create',
  EDIT_TRANSACTION: '/transaction-logs/edit',
  QUOTATIONS: '/quotations',
  SECTIONS: '/sections',
  KEYWORDS: '/keywords',
  LAWYERS: '/lawyers',
  REPORTS: '/reports',
  APPEALS: '/appeals',
  REVIEW_APPEALS: '/review-appeals',
  PAYMENTS: '/payments',
  ADD_PAYMENT: '/payments/create',
  EDIT_PAYMENT: '/payments/edit/:id',
};

/*  Modules */
export const MODULES = {
  DASHBOARD: 'Dashboard',
  USER: 'Users',
  TEMPLATE_CATEGORY: 'Template Categories',
  TEMPLATE: 'Templates',
  SUBSCRIPTION_PLANS: 'Subscription Plans',
  JUDGES: 'Judges',
  ASSESSES: 'Assesses',
  PAYMENT_DISTRIBUTION: 'Payment Distribution',
  JUDGEMENTS: 'Judgements',
  APPEALS: 'Appeals',
  CONSULTATION_CALLS: 'Consultation Calls',
  TRANSACTION_LOGS: 'Transaction Logs',
  QUOTATIONS: 'Quotations',
  SECTIONS: 'Sections',
  KEYWORDS: 'Keywords',
  LAWYERS: 'Lawyers',
  REPORTS: 'Reports',
  REVIEW_APPEALS: 'Review Appeals',
  PAYMENTS: 'Payments',
};

/* Authentication */
export const TOKEN = 'TOKEN';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const USER = 'USER';

export const ROLES = {
  SUPER_ADMIN: 'Super admin',
  ADMIN: 'Admin',
};

export const ROLE_KEYS = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  ADMIN: 'ADMIN',
  ADVISER: 'ADVISER',
  ADVICE_SEEKER: 'ADVICE_SEEKER',
  STAFF: 'STAFF',
  PREMIUM_CONSULTANT: 'PREMIUM_CONSULTANT',
};

export const STATUS_TAG_COLOR = {
  PUBLISHED: 'success',
  DRAFT: 'default',
};

export const SORT = {
  ASC: 'ascend',
  DESC: 'descend',
};

export const SORT_OPTION = {
  ASC: 'ASC',
  DESC: 'DESC',
};

/* Date and time */
export const defaultDateFormat = 'MM/DD/YYYY';
export const judgementDateFormat = 'MMM DD YYYY, hh:mm a';
export const transactionDate = 'Do MMM, YYYY';
export const TIME_FORMAT = 'hh:mma';
export const STANDARD_DATE_FORMAT = 'DD/MM/YYYY';

export const REGEX = {
  NAME: /^[a-z0-9 ,.'-]+$/i,
  ZIPCODE: /^[0-9]{6}$/,
  CITY: /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/,
  WEB_URL: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
  PASSWORD: /^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*]{8,16}$/,
  PHONE: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
  MOBILE_NUMBER: /^[6789]\d{9}$/,
  EMAIL: /^(([^<>()[\]\\.,;:!#$*%^'`~={}?/&\s@"]+(\.[^<>()[\]\\.,;:!#$*%^'`~={}?/&\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  AMOUNT: /^\d+$|^\d+\.\d*$/,
  OPTIONAL_NEGATIVE_AMOUNT: /^[-]?\d+$|^[-]?\d+\.\d*$/,
  NUMBER: /^\d+$/,
  COUNTRY_CODE_PHONE: /^[0-9]{7,12}$/,
  PANCARD: /^[a-zA-Z]{3}[p|P|c|C|h|H|f|F|a|A|t|T|b|B|l|L|j|J|g|G][A-Za-z][\d]{4}[A-Za-z]$/gm,
};

export const LIMIT = 10;
export const SCROLL_PERCENT = 0.85;
export const MAX_PRICE_LIMIT = 50000;
export const PAGE_SIZE_OPTIONS = [10, 20, 50];

export const GENDER = [
  { name: 'Female', value: 'FEMALE' },
  { name: 'Male', value: 'MALE' },
  { name: 'Other', value: 'OTHER' },
];
export const ERROR_PAGE_TITLE = 'Oops! An error occurred!';
export const ERROR_PAGE_SUBTITLE =
  'Something is broken. Please let us know what you were doing when this error occurred. We will fix it as soon as possible. Sorry for any inconvenience caused.';

export const GUTTER_VARIATIONS = { xs: 16, sm: 16, md: 24, lg: 32 };

export const TEMPLATE_STATUS_COLOR = {
  DRAFT: '#faad14',
  PUBLISHED: '#52c41a',
  UNPUBLISHED: '#FA541C',
};

export const TEMPLATE_TYPE = {
  FREE: 'FREE',
  PAID: 'PAID',
  PREMIUM: 'PREMIUM',
};

export const USER_TYPE = {
  ADVISER: 'ADVISER',
  STAFF: 'STAFF',
  ADMIN: 'ADMIN',
  ADVICE_SEEKER: 'ADVICE_SEEKER',
  SUPER_ADMIN: 'SUPER_ADMIN',
  PREMIUM_CONSULTANT: 'PREMIUM_CONSULTANT',
};

export const USER_TYPE_LABEL = {
  ADVISER: 'Adviser',
  STAFF: 'Staff',
  ADMIN: 'Admin',
  ADVICE_SEEKER: 'Advice seeker',
  SUPER_ADMIN: 'Super admin',
  PREMIUM_CONSULTANT: 'Premium consultant',
};

export const ROLES_SELECTION_FOR_STAFF = [
  { value: 'PREMIUM_CONSULTANT', label: 'Premium consultant' },
  { value: 'ADVISER', label: 'Adviser' },
  { value: 'ADVICE_SEEKER', label: 'Advice seeker' },
];

export const ROLES_SELECTION_FOR_SUPER_ADMIN_AND_ADMIN = [
  { value: 'ADMIN', label: 'Admin' },
  { value: 'STAFF', label: 'Staff' },
  { value: 'PREMIUM_CONSULTANT', label: 'Premium consultant' },
  { value: 'ADVISER', label: 'Adviser' },
  { value: 'ADVICE_SEEKER', label: 'Advice seeker' },
];

export const BREAKPOINTS = {
  mobile: 550,
  tablet: 767,
  desktop: 1000,
  hd: 1200,
};

export const TAX_CATEGORIES = {
  DIRECT_TAX: 'Direct Tax',
  INDIRECT_TAX: 'Indirect Tax',
  COMPANY_LAW: 'Company Law',
};

export const COURT_LIST = {
  HIGH_COURT: 'High Court',
  SUPREME_COURT: 'Supreme Court',
  TRIBUNAL_COURT: 'Tribunal Court',
};

export const JUDGES_TYPE = {
  JUDICIAL_MEMBER: 'judicial Member',
  ACCOUNTANT_MEMBER: 'Accountant Member',
};

export const PAYMENT_DISTRIBUTION_KEYS = {
  TYPE: 'Type',
  SUBSCRIPTION_BASED_COMMISSION_IN_PERCENT:
    'Subscription based commission in percent',
  SERVICE_CHARGES_IN_PERCENT: 'Service charges in percent',
  REFERRAL_CHARGES_IN_PERCENT: 'Referral charges in percent',
  PAID_PREMIUM_BASED_COMMISSION_IN_PERCENT:
    'Paid Premium based commission in percent',
  CONSULTATION_CALL_VALUE_IN_PERCENT: 'Consultation call value in percent',
  CONSULTATION_CALL_TYPE: 'Consultation call type',
};

export const CONSULTATION_CALL_TYPE = {
  FIXED: { key: 'FIXED', label: 'Fixed' },
  PERCENT: { key: 'PERCENT', label: 'Percent' },
};

export const CONSULTATION_CALL_FILTER = [
  { value: 'ONE_TO_ONE', label: '1-To-1' },
  { value: 'WEBINAR', label: 'Webinar' },
];

export const CALL_TYPE = {
  ONE_TO_ONE: 'ONE_TO_ONE',
  WEBINAR: 'WEBINAR',
};

export const TRANSACTION_LOGS_FILTER = [
  { value: 'TEMPLATE', label: 'Template' },
  { value: 'SUBSCRIPTION', label: 'Subscription' },
  { value: 'CALL', label: 'Consultation Call' },
  // { value: 'SERVICE', label: 'Services' },
  // { value: 'REFERRING_SERVICE', label: 'Referring Service' },
];

export const TRANSACTION_TYPE = {
  TEMPLATE: 'TEMPLATE',
  SUBSCRIPTION: 'SUBSCRIPTION',
  CALL: 'CALL',
  // SERVICE: 'SERVICE',
  // REFERRING_SERVICE: 'REFERRING_SERVICE',
};

export const TRANSACTION_STATUS_COLOR = {
  SETTLED: 'success',
  PENDING: 'processing',
  RESOLVED: 'magenta',
  IN_DISPUTE: 'volcano',
  CANCELED: 'error',
};

export const REPORT_STATUS_COLOR = {
  COMPLETED: 'success',
  PENDING: 'processing',
  FAILED: 'error',
};

export const TRANSACTION_STATUS_LABELS = {
  SETTLED: 'Settled',
  PENDING: 'Pending',
  RESOLVED: 'Resolved',
  IN_DISPUTE: 'In-Dispute',
  CANCELED: 'Canceled',
};

export const TRANSACTION_STATUS_SELECTION = [
  { value: 'SETTLED', label: 'Settled' },
  { value: 'PENDING', label: 'Pending' },
  { value: 'RESOLVED', label: 'Resolved' },
  { value: 'IN_DISPUTE', label: 'In-Dispute' },
  { value: 'CANCELED', label: 'Canceled' },
];

export const TEMPLATE_STATUS = {
  DRAFT: 'DRAFT',
  PUBLISHED: 'PUBLISHED',
  REJECTED: 'REJECTED',
  UNPUBLISHED: 'UNPUBLISHED',
};

export const STATUS_SELECTION = [
  { value: 'DRAFT', label: 'Draft' },
  { value: 'PUBLISHED', label: 'Publish' },
  { value: 'REJECTED', label: 'Reject' },
  { value: 'UNPUBLISHED', label: 'Unpublish' },
];

export const BOOKED_FROM_SELECTION = {
  WEB: { label: 'Counselvise', value: 'WEB' },
  MICROSITE: { label: 'Microsite', value: 'MICROSITE' },
};

export const PAYMENT_TYPE = {
  DEFAULT: { key: 'DEFAULT', label: 'Default' },
  SPECIFIC: { key: 'SPECIFIC', label: 'Specific' },
};

export const DYNAMIC_TAGS_LENGTH = 20;
export const PAGE_TYPE = {
  TEMPLATE: 'TEMPLATE',
  PLAN: 'SUBSCRIPTION_PLAN',
  CONSULTATION_CALL: 'CONSULTATION_CALL',
};

export const SHOW_ON_PAGE_OPTIONS = [
  {
    label: 'Direct tax',
    value: 'DIRECT_TAX',
  },
  {
    label: 'Indirect tax',
    value: 'INDIRECT_TAX',
  },
  {
    label: 'Corporate law',
    value: 'CORPORATE_LAW',
  },
];

export const EXPERT_SUMMARY_STATUS_SELECTION = [
  { value: 'DRAFT', label: 'Draft' },
  { value: 'PUBLISHED', label: 'Publish' },
];

export const PLAN_STATUS = { PUBLISHED: 'PUBLISHED' };

export const USER_ROLES_FILTERS = [
  {
    value: USER_TYPE.ADVISER,
    label: USER_TYPE_LABEL.ADVISER,
  },
  {
    value: USER_TYPE.ADVICE_SEEKER,
    label: USER_TYPE_LABEL.ADVICE_SEEKER,
  },
  {
    value: USER_TYPE.ADMIN,
    label: USER_TYPE_LABEL.ADMIN,
  },
  {
    value: USER_TYPE.STAFF,
    label: USER_TYPE_LABEL.STAFF,
  },
  {
    value: USER_TYPE.PREMIUM_CONSULTANT,
    label: USER_TYPE_LABEL.PREMIUM_CONSULTANT,
  },
];

export const RefDataType = {
  call: 'CALL',
  expertSummary: 'EXPERT_SUMMARY',
  judgement: 'JUDGEMENT',
  template: 'TEMPLATE',
};

export const RefDataCallType = {
  oneToOne: 'ONE_TO_ONE',
};

export const SUMMARY_STATUS = {
  PUBLISHED: 'success',
  UNPUBLISHED: 'error',
  DRAFT: 'processing',
};

export const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export const SUBSCRIPTION_DOWNLOADABLE = {
  DIRECT_TAX: 'downloadableDirectTaxJudgements',
  INDIRECT_TAX: 'downloadableIndirectTaxJudgements',
  COMPANY_LAW: 'downloadableCompanyLawJudgements',
  TEMPLATES: 'downloadableTemplate',
  ALL_IN_ONE: 'totalJudgements',
};
export const MAX_ORDER_LIMIT = 10000;
