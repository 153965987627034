import { InfoCircleOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Space,
} from 'antd';
import { City, State } from 'country-state-city';
import React, { useEffect, useState } from 'react';
import {
  REGEX,
  ROLE_KEYS,
  SORT_OPTION,
  TEMPLATE_STATUS,
  TRANSACTION_LOGS_FILTER,
  TRANSACTION_STATUS_SELECTION,
  TRANSACTION_TYPE,
} from '../../../common/constants';
import { formValidatorRules } from '../../../common/utils';
import CommonSelect from '../../../components/CommonSelect';
import useRouter from '../../../hooks/useRouter';
import {
  CONSULTATION_CALLS,
  GET_CONSULTATION_CALL_DETAILS,
} from '../../consultation-calls/graphql/queries';
import { LIST_SUBSCRIPTION_PLANS } from '../../subscription-plans/graphql/queries';
import { FETCH_TEMPLATE_LIST } from '../../template/graphql/queries';
import { LIST_USER_QUERY } from '../../users/graphql/queries';
import CallBookingCalendar from './CallBookingCalendar';

const { mobileNumber } = formValidatorRules;

export default function TransactionForm({
  form,
  onFinish,
  loading,
  initialValues = null,
  id,
  setIsPaymentCanceled,
  isPaymentCanceled,
  handleSuccessBooking,
  showModal,
  setShowModal,
  isBooked,
  role,
}) {
  const userRole = role && role.length ? role?.[0] : null;
  const { navigate } = useRouter();
  const [viewCalendar, setViewCalendar] = useState(false);
  const [isFieldsChanged, setIsFieldsChanged] = useState(false);
  const [stateCode, setStateCode] = useState('');

  const selectedType = Form.useWatch('type', form);
  const useFetchData = (query, onCompleted, options = {}) =>
    useLazyQuery(query, {
      fetchPolicy: 'network-only',
      onCompleted,
      onError: () => {},
      ...options,
    });

  const [
    fetchConsultationCall,
    { data: callData },
  ] = useFetchData(GET_CONSULTATION_CALL_DETAILS, () => setViewCalendar(true));

  const handlePaymentStatus = (value) => {
    setIsPaymentCanceled(value === 'CANCELED');
  };

  const handleItemSelection = (callId) => {
    if (selectedType === TRANSACTION_TYPE.CALL && callId) {
      fetchConsultationCall({
        variables: {
          where: {
            id: callId,
          },
        },
      });
    } else {
      setViewCalendar(false);
    }
  };

  useEffect(() => {
    form.validateFields(['create']);
  }, [isBooked]);

  const getQuery = (type) => {
    switch (type) {
      case TRANSACTION_TYPE.TEMPLATE:
        return FETCH_TEMPLATE_LIST;
      case TRANSACTION_TYPE.CALL:
        return CONSULTATION_CALLS;
      case TRANSACTION_TYPE.SUBSCRIPTION:
        return LIST_SUBSCRIPTION_PLANS;
      default:
        return LIST_SUBSCRIPTION_PLANS;
    }
  };

  const getResponsePath = (type) => {
    switch (type) {
      case TRANSACTION_TYPE.TEMPLATE:
        return 'templatesAdmin.data';
      case TRANSACTION_TYPE.CALL:
        return 'consultationCallsAdmin.data';
      case TRANSACTION_TYPE.SUBSCRIPTION:
        return 'subscriptionPlansAdmin.data';
      default:
        return '';
    }
  };

  const getVariables = (type) => {
    const baseVariables = {
      sort: [
        {
          sortOn: 'title',
          sortBy: SORT_OPTION.ASC,
        },
      ],
    };

    switch (type) {
      case TRANSACTION_TYPE.TEMPLATE:
        return {
          ...baseVariables,
          filter: {
            ...baseVariables.filter,
            status: TEMPLATE_STATUS.PUBLISHED,
          },
        };
      case TRANSACTION_TYPE.CALL:
        return {
          ...baseVariables,
          filter: {
            ...baseVariables.filter,
            status: TEMPLATE_STATUS.PUBLISHED,
            isFeatured: true,
          },
        };
      case TRANSACTION_TYPE.SUBSCRIPTION:
        return baseVariables;
      default:
        return {
          ...baseVariables,
          filter: {
            ...baseVariables.filter,
          },
        };
    }
  };

  const stateOption = State?.getStatesOfCountry('IN')?.map((state) => ({
    value: state?.name,
    label: state?.name,
    stateCode: state?.isoCode,
  }));

  const handleStateChange = (e, value) => {
    form?.setFieldValue('city', null);
    if (!Array.isArray(value)) {
      setStateCode(value?.stateCode);
    }
  };

  return (
    <>
      <Card className="ant-body-scroll">
        <div className="card-body-wrapper">
          <Form
            form={form}
            name="register"
            onFinish={onFinish}
            initialValues={initialValues}
            onValuesChange={() => setIsFieldsChanged(true)}
            layout="vertical"
          >
            <Row gutter={16}>
              <Col xs={24} lg={8} xl={8}>
                <Form.Item
                  name="userId"
                  label="User"
                  rules={[
                    {
                      required: !id,
                      message: 'Please select an User',
                    },
                  ]}
                >
                  <CommonSelect
                    getPopupContainer={(trigger) => trigger?.parentNode}
                    placeholder="Select an User"
                    showSearch
                    allowClear
                    disabled={id}
                    query={LIST_USER_QUERY}
                    fetchPolicy="network-only"
                    responsePath="listUsers.user"
                    valuePath="id"
                    labelPath="firstName"
                    optionalLabelPath="lastName"
                    variables={{
                      filter: {
                        roles: [ROLE_KEYS.ADVISER, ROLE_KEYS.ADVICE_SEEKER],
                      },
                      sort: [
                        {
                          sortOn: 'createdAt',
                          sortBy: SORT_OPTION.DESC,
                        },
                      ],
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={8} xl={8}>
                <Form.Item
                  name="type"
                  label="Type"
                  rules={[
                    {
                      required: !id,
                      message: 'Please select a Type',
                    },
                  ]}
                >
                  <Select
                    placeholder="Select Type"
                    onChange={(value) => {
                      form?.setFieldValue('itemId', null);
                      if (value !== TRANSACTION_TYPE.CALL) {
                        setViewCalendar(false);
                      }
                    }}
                    disabled={id}
                  >
                    {TRANSACTION_LOGS_FILTER.map((type) => (
                      <Select.Option key={type?.value} value={type?.value}>
                        {type?.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={8} xl={8}>
                <Form.Item
                  name="itemId"
                  label="Item"
                  rules={[
                    {
                      required: !id,
                      message: 'Please select an Item',
                    },
                  ]}
                >
                  <CommonSelect
                    disabled={!selectedType || id}
                    getPopupContainer={(trigger) => trigger?.parentNode}
                    placeholder="Select an Item"
                    showSearch
                    allowClear
                    onChange={handleItemSelection}
                    query={getQuery(selectedType)}
                    fetchPolicy="network-only"
                    valuePath="id"
                    labelPath="title"
                    responsePath={getResponsePath(selectedType)}
                    useEffectDeps={[selectedType]}
                    isDataDependent={[selectedType]}
                    variables={getVariables(selectedType)}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={8} xl={8}>
                <Form.Item
                  name={id ? 'status' : null}
                  label="Status"
                  className="user-type-input"
                  rules={[
                    {
                      required: !id,
                      message: 'Please select a Status',
                    },
                  ]}
                >
                  <Select
                    disabled={!id || userRole === ROLE_KEYS.ADVISER}
                    defaultValue={!id ? 'SETTLED' : null}
                    placeholder="Select Status"
                    onChange={handlePaymentStatus}
                  >
                    {TRANSACTION_STATUS_SELECTION.map((type) => (
                      <Select.Option key={type?.value} value={type?.value}>
                        {type?.label}
                      </Select.Option>
                    ))}
                  </Select>
                  {userRole === ROLE_KEYS.ADVISER && (
                    <div className="error-message mt-8">
                      <span className="mr-8">
                        <InfoCircleOutlined />
                      </span>
                      Adviser's status is not editable.
                    </div>
                  )}
                </Form.Item>
              </Col>
              {isPaymentCanceled && (
                <Col xs={24} lg={8} xl={8}>
                  <Form.Item
                    name="reason"
                    label="Reason"
                    rules={[
                      {
                        whitespace: true,
                        required: isPaymentCanceled,
                        message: 'Please enter Reason',
                      },
                    ]}
                  >
                    <Input placeholder="Enter Reason" />
                  </Form.Item>
                </Col>
              )}
              {viewCalendar && form?.getFieldValue('itemId') && (
                <Col xs={24} lg={8} xl={8}>
                  <Form.Item
                    name="create"
                    label="Create Booking"
                    rules={[
                      {
                        required: !isBooked,
                        message: 'Please Create Booking',
                      },
                    ]}
                  >
                    <Button type="primary" onClick={() => setShowModal(true)}>
                      Create Booking
                    </Button>
                  </Form.Item>
                </Col>
              )}
              <Divider orientation="left" plain>
                <h3>Billing Details</h3>
              </Divider>
              <Form.List name="billing">
                {({ errors }) => (
                  <>
                    <Col xs={24} lg={8} xl={8}>
                      <Form.Item
                        name="firstName"
                        label="First Name"
                        rules={[
                          {
                            whitespace: true,
                            required: !id,
                            message: 'Please enter First Name',
                          },
                        ]}
                      >
                        <Input disabled={id} placeholder="First Name" />
                      </Form.Item>
                    </Col>
                    <Col xs={24} lg={8} xl={8}>
                      <Form.Item
                        name="lastName"
                        label="Last Name"
                        rules={[
                          {
                            whitespace: true,
                            required: !id,
                            message: 'Please enter Last Name',
                          },
                        ]}
                      >
                        <Input disabled={id} placeholder="Last Name" />
                      </Form.Item>
                    </Col>
                    <Col xs={24} lg={8} xl={8}>
                      <Form.Item
                        name="email"
                        label="Email"
                        rules={[
                          {
                            whitespace: true,
                            required: !id,
                            message: 'Please enter Email',
                          },
                        ]}
                      >
                        <Input disabled={id} placeholder="Email" />
                      </Form.Item>
                    </Col>
                    <Col xs={24} lg={8} xl={8}>
                      <Form.Item
                        name="phone"
                        label="Phone Number"
                        rules={[
                          {
                            required: !id,
                            message: 'Please enter Phone Number',
                          },
                          mobileNumber,
                        ]}
                      >
                        <Input
                          type="number"
                          disabled={id}
                          placeholder="Phone Number"
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} lg={8} xl={8}>
                      <Form.Item
                        name="billingName"
                        label="Billing/Company Name"
                        rules={[
                          {
                            whitespace: true,
                            required: !id,
                            message: 'Please enter Billing/Company Name',
                          },
                        ]}
                      >
                        <Input disabled={id} placeholder="Billing Name" />
                      </Form.Item>
                    </Col>
                    <Col xs={24} lg={8} xl={8}>
                      <Form.Item name="referralCode" label="Referral Code">
                        <Input disabled={id} placeholder="Referral Code" />
                      </Form.Item>
                    </Col>
                    <Col span={16}>
                      <Form.Item
                        name="address"
                        label="Address"
                        rules={[
                          {
                            whitespace: true,
                            required: !id,
                            message: 'Please enter Address',
                          },
                        ]}
                      >
                        <Input disabled={id} placeholder="Address" />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        name="pinCode"
                        label="Pincode"
                        rules={[
                          {
                            whitespace: true,
                            required: !id,
                            message: 'Please enter Pincode',
                          },
                          {
                            pattern: REGEX.ZIPCODE,
                            message: 'Pincode must be a 6 digit number',
                          },
                        ]}
                      >
                        <Input disabled={id} placeholder="Pincode" />
                      </Form.Item>
                    </Col>
                    <Col xs={24} lg={8} xl={8}>
                      <Form.Item
                        name="state"
                        label="State"
                        rules={[
                          {
                            required: !id,
                            message: 'Please select State',
                          },
                        ]}
                      >
                        <Select
                          disabled={id}
                          className="state-input"
                          placeholder="State"
                          showSearch
                          options={stateOption}
                          onChange={handleStateChange}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} lg={8} xl={8}>
                      <Form.Item
                        name="city"
                        label="City"
                        className="form-label"
                        rules={[
                          {
                            required: !id,
                            message: 'Please select City',
                          },
                        ]}
                      >
                        <Select
                          disabled={stateCode === '' || id}
                          className="state-input"
                          placeholder="City"
                          showSearch
                          options={City?.getCitiesOfState('IN', stateCode)?.map(
                            (city) => ({
                              value: city?.name,
                              label: city?.name,
                            }),
                          )}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} lg={8} xl={8}>
                      <Form.Item name="gstNumber" label="GST Number(optional)">
                        <Input
                          disabled={id}
                          placeholder="GST Number(optional)"
                        />
                      </Form.Item>
                    </Col>
                    <Form.ErrorList
                      className="category-error"
                      errors={errors}
                    />
                  </>
                )}
              </Form.List>
            </Row>
            <Form.Item>
              <Space size={8}>
                <Button onClick={() => navigate(-1)}>Cancel</Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  disabled={!isFieldsChanged}
                >
                  Save
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </div>
      </Card>
      {viewCalendar && showModal && (
        <CallBookingCalendar
          callType={callData?.consultationCallAdmin?.type}
          userSlug={callData?.consultationCallAdmin?.guestUserData?.slug}
          eventSlug={callData?.consultationCallAdmin?.slug}
          workspaceId={callData?.consultationCallAdmin?.workspaceData?.uuid}
          handleSuccessBooking={handleSuccessBooking}
          showModal={showModal}
          setShowModal={setShowModal}
        />
      )}
    </>
  );
}
