import React, { useEffect, useState } from 'react';
import { mongoClient } from '../apollo';
import { LIMIT } from '../common/constants';
import { FETCH_BENCH_LIST } from '../modules/users/graphql/queries';
import DebounceSelectComponent from './DebounceSelectComponent';

const commonInitialVariable = {
  filter: {
    skip: 0,
    limit: LIMIT,
    search: '',
  },
  sort: { sortBy: 'DESC', sortOn: 'createdAt' },
};

function BenchListSelect({ onChange, values, mode }) {
  const [isBenchesEnd, setIsBenchesEnd] = useState(false);
  const [benchesOptions, setBenchesOptions] = useState([]);
  const [benchesVariable, setBenchesVariable] = useState(commonInitialVariable);

  const listBenches = async (variables = benchesVariable, isScroll = false) => {
    try {
      const listData = await mongoClient?.query({
        query: FETCH_BENCH_LIST,
        fetchPolicy: 'network-only',
        variables: {
          ...variables,
          filter: {
            ...variables.filter,
            skip: isScroll ? benchesOptions?.length : 0,
          },
        },
      });
      let prepareOptions = [];
      const count = listData?.data?.benches?.length;
      if (count) {
        prepareOptions = listData?.data?.benches?.map((item) => ({
          key: item?.key,
          label: item?.value,
          // eslint-disable-next-line no-underscore-dangle
          value: item?._id,
        }));
      }
      setIsBenchesEnd(count < LIMIT);
      setBenchesOptions(
        isScroll ? [...benchesOptions, ...prepareOptions] : prepareOptions,
      );
      setBenchesVariable(variables);
      return prepareOptions;
      // eslint-disable-next-line no-empty
    } catch (error) {}
  };

  useEffect(() => {
    listBenches(commonInitialVariable, false);
  }, [commonInitialVariable]);

  const handleScroll = async (event) => {
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};
    const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
    if (scrolledToBottom) {
      if (!isBenchesEnd) {
        return listBenches(benchesVariable, true);
      }
    }
  };

  return (
    <DebounceSelectComponent
      onPopupScroll={(e) => handleScroll(e, 'BENCH')}
      placeholder="Select Benches"
      mode={mode}
      onSearch={(e) =>
        listBenches(
          {
            ...benchesVariable,
            filter: {
              ...benchesVariable.filter,
              search: e,
              skip: 0,
            },
          },
          false,
        )
      }
      onFocus={() => !benchesOptions?.length && listBenches()}
      options={benchesOptions}
      // eslint-disable-next-line no-console
      onChange={onChange}
      values={values}
    />
  );
}

export default BenchListSelect;
