import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, Input, Row, Select, Space } from 'antd';
import React from 'react';
import { TAX_CATEGORIES } from '../../../common/constants';
import { formValidatorRules } from '../../../common/utils';
import useRouter from '../../../hooks/useRouter';

const { required, pancard } = formValidatorRules;

function AssesseeForm({ form, onFinish, defaultFormValue = null, loading }) {
  const { navigate } = useRouter();

  return (
    <Card className="ant-body-scroll">
      <div className="card-body-wrapper">
        <Form
          form={form}
          name="register"
          onFinish={onFinish}
          initialValues={defaultFormValue}
          layout="vertical"
        >
          <Row gutter={16}>
            <Col xs={24} sm={12} md={8}>
              <Form.Item
                name="name"
                label="Name"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: 'Please enter Name',
                  },
                ]}
              >
                <Input placeholder="Enter your Name" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <Form.Item
                name="initialOfSurnameOrTitle"
                label="Initial of Surname or Title"
                rules={[
                  {
                    whitespace: true,
                    message: 'Please enter Surname/Title',
                  },
                ]}
              >
                <Input placeholder="Enter your Surname or Title" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <Form.Item
                name="pancard"
                label="Pan"
                rules={[
                  pancard,
                  { required: true, message: 'Please enter Pan Number' },
                ]}
              >
                <Input placeholder="Enter your Pan Number" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <Form.Item name="type" label="Type" className="user-type-input">
                <Input placeholder="Enter Type" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <Form.Item
                name="category"
                label="Category"
                className="user-type-input"
                rules={[
                  {
                    required: true,
                    message: 'Please select a Category',
                  },
                ]}
              >
                <Select placeholder="Select Type" mode="multiple">
                  {Object.entries(TAX_CATEGORIES).map(([value, label]) => (
                    <Select.Option key={value} value={value}>
                      {label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <Form.Item
                name="slug"
                label="Slug"
                rules={[{ required, message: 'Please enter Slug' }]}
              >
                <Input placeholder="Enter your Slug" />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col xs={24} sm={12} md={7} span={7}>
              <p className="mt-0 mb-8 feature-label">Court</p>
              <Form.List
                name="court"
                rules={[
                  {
                    validator: async (_, names) => {
                      if (!names || names?.length < 1) {
                        return Promise.reject(
                          new Error('At least 1 Court must be added'),
                        );
                      }
                    },
                  },
                ]}
              >
                {(fields, { add, remove }, { errors }) => (
                  <>
                    {fields?.map(({ key, name, ...restField }) => (
                      <Space
                        className="dynamic-cat-input"
                        key={key}
                        align="baseline"
                      >
                        <Form.Item
                          {...restField}
                          name={[name]}
                          rules={[
                            {
                              required: true,
                              message: 'Please enter Court',
                            },
                          ]}
                        >
                          <Input
                            className="input-cat"
                            placeholder="Enter Court"
                          />
                        </Form.Item>
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </Space>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        Add more Court
                      </Button>
                    </Form.Item>
                    <Form.ErrorList
                      className="category-error"
                      errors={errors}
                    />
                  </>
                )}
              </Form.List>
            </Col>
          </Row>
          <Form.Item>
            <Space size={8}>
              <Button onClick={() => navigate(-1)}>Cancel</Button>
              <Button type="primary" htmlType="submit" loading={loading}>
                Save
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </div>
    </Card>
  );
}

export default AssesseeForm;
