import { useLazyQuery } from '@apollo/client';
import { Badge, Button, Radio, Select, Table, Tag, Typography } from 'antd';
import { debounce, map } from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  CONSULTATION_CALL_FILTER,
  LIMIT,
  PAGE_SIZE_OPTIONS,
  ROLES_SELECTION_FOR_SUPER_ADMIN_AND_ADMIN,
  ROLE_KEYS,
  ROUTES,
  SORT_OPTION,
  TEMPLATE_STATUS_COLOR,
  USER_TYPE,
} from '../../common/constants';
import CommonSelect from '../../components/CommonSelect';
import SearchComponent from '../../components/SearchComponent';
import useRouter from '../../hooks/useRouter';
import { LIST_USER_QUERY } from '../users/graphql/queries';
import { CONSULTATION_CALLS } from './graphql/queries';

const { Title } = Typography;

function ConsultationCallsList() {
  const { navigate } = useRouter();
  const [variable, setVariable] = useState({
    filter: {
      limit: LIMIT,
      skip: 0,
      search: '',
      types: [],
    },
    sort: [{ sortBy: 'DESC', sortOn: 'createdAt' }],
  });
  const [consultationCallsData, setConsultationCallsData] = useState([]);
  const CallsOwnerType = ROLES_SELECTION_FOR_SUPER_ADMIN_AND_ADMIN?.filter(
    (roles) =>
      roles?.value === ROLE_KEYS.PREMIUM_CONSULTANT ||
      roles?.value === ROLE_KEYS.ADVISER,
  );
  const [totalCount, setTotalCount] = useState(null);
  const [getConsultationCalls, { loading }] = useLazyQuery(CONSULTATION_CALLS, {
    fetchPolicy: 'network-only',
    onError() {},
  });

  const handleSearchChange = debounce((value) => {
    setVariable({
      ...variable,
      filter: { ...variable?.filter, skip: 0, search: value },
    });
  }, 1000);

  const handleRoleChange = (roles) => {
    setVariable({
      ...variable,
      filter: { ...variable?.filter, roles },
    });
  };

  useEffect(() => {
    getConsultationCalls({
      variables: variable,
      onCompleted: (res) => {
        setTotalCount(res?.consultationCallsAdmin?.count);
        setConsultationCallsData(res?.consultationCallsAdmin?.data);
      },
    });
  }, [variable]);

  const handleTableChange = (pagination, filters, sorter) => {
    let prepareVariable = variable?.filter;
    if (Object.keys(pagination)?.length > 0) {
      prepareVariable = {
        ...variable.filter,
        filter: { ...prepareVariable, skip: (pagination?.current - 1) * LIMIT },
      };
    }
    if (Object.keys(filters)?.length > 0) {
      prepareVariable = {
        filter: {
          ...prepareVariable?.filter,
          types:
            filters?.types && filters?.types?.length > 0 && filters?.types[0],
        },
      };
    }
    if (Object.keys(sorter)?.length > 0) {
      prepareVariable = {
        ...prepareVariable,
        sort: [
          {
            sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC',
            sortOn:
              sorter?.field && sorter?.order ? sorter?.field : 'createdAt',
          },
        ],
      };
    }
    setVariable({ ...prepareVariable });
  };
  const getTypeCallFilterProps = () => ({
    filterMultiple: false,
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div className="custom-filter-dropdown">
        {CONSULTATION_CALL_FILTER.map((option) => (
          <div key={option?.label} className="mt-6 mb-6">
            <Radio
              value={option?.value}
              checked={selectedKeys.includes(option?.value)}
              onChange={(e) => {
                const updatedKeys = e.target.checked ? [option?.value] : [];
                setSelectedKeys(updatedKeys);
              }}
            >
              {option?.label}
            </Radio>
          </div>
        ))}
        <div className="control-panel">
          <Button
            type="primary"
            size="small"
            onClick={() => {
              confirm();
            }}
            className="ok-button"
          >
            Apply
          </Button>
          <Button
            className="ml-8 reset-button"
            size="small"
            onClick={() => {
              setSelectedKeys([]);
              confirm();
            }}
          >
            Reset
          </Button>
        </div>
      </div>
    ),
  });

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      width: '20%',
    },
    {
      title: 'Creator',
      render: (callDetail) =>
        `${callDetail?.creator?.firstName} ${callDetail?.creator?.lastName}`,
      width: '20%',
    },
    {
      title: 'Type',
      render: (callDetail) => (
        <Tag color="processing">
          {callDetail?.type === 'ONE_TO_ONE' ? '1-to-1' : 'Webinar'}
        </Tag>
      ),
      key: 'types',
      width: '10%',
      filterMultiple: false,
      ...getTypeCallFilterProps(),
    },
    {
      title: 'No. of Bookings',
      dataIndex: 'bookedCount',
      width: '10%',
    },
    {
      title: 'Price (INR)',
      dataIndex: 'price',
      width: '10%',
      sorter: true,
      key: 'price',
    },
    {
      title: 'Duration (In Minutes)',
      dataIndex: 'duration',
      width: '10%',
    },
    {
      title: 'Featured',
      width: '10%',
      render: (item) =>
        // eslint-disable-next-line no-nested-ternary
        item?.creator?.roles?.[0] === USER_TYPE.PREMIUM_CONSULTANT ? (
          item?.isFeatured ? (
            <Badge count="True" color={TEMPLATE_STATUS_COLOR.PUBLISHED} />
          ) : (
            <Badge count="False" color={TEMPLATE_STATUS_COLOR.UNPUBLISHED} />
          )
        ) : (
          '-'
        ),
    },
  ];

  const handleUser = (value) => {
    let prepareValue = variable;
    if (value) {
      prepareValue = {
        ...variable,
        filter: { ...variable?.filter, userId: value },
      };
    } else {
      prepareValue = delete prepareValue?.filter?.userId;
    }
    setVariable({ ...prepareValue });
  };

  return (
    <>
      <Title className="site-page-header p-0 mb-8 mt-0" level={3}>
        Consultation Calls
        {totalCount ? ` (${totalCount})` : ''}
      </Title>
      <div className="filter-input mb-12">
        <SearchComponent className="list-search" getData={handleSearchChange} />
        <div className="d-flex gap-12">
          <div className="d-flex gap-12">
            <Select
              allowClear
              placeholder="Select User Role"
              onChange={handleRoleChange}
              className="role-select-in-calls"
            >
              {map(CallsOwnerType, (call) => (
                <Select.Option key={call?.value}>{call?.label}</Select.Option>
              ))}
            </Select>
          </div>
          <CommonSelect
            placeholder="Select User by Name"
            className="role-select-in-calls pr-4 pl-4 "
            showSearch
            allowClear
            onChange={handleUser}
            query={LIST_USER_QUERY}
            fetchPolicy="network-only"
            responsePath="listUsers.user"
            valuePath="id"
            labelPath="firstName"
            optionalLabelPath="lastName"
            useEffectDeps={[variable?.filter?.roles]}
            isDataDependent={[variable?.filter?.roles]}
            variables={{
              filter: {
                roles: variable?.filter?.roles
                  ? variable?.filter?.roles
                  : [ROLE_KEYS.PREMIUM_CONSULTANT, ROLE_KEYS.ADVISER],
              },
              sort: [
                {
                  sortOn: 'createdAt',
                  sortBy: SORT_OPTION?.DESC,
                },
              ],
            }}
          />
        </div>
      </div>
      <Table
        columns={columns}
        rowKey={(record) => record?.id}
        dataSource={consultationCallsData}
        loading={loading}
        onChange={handleTableChange}
        scroll={{ x: 1300, y: `calc(100vh - 342px)` }}
        onRow={(record) => ({
          onClick: () => {
            navigate(`${ROUTES.CONSULTATION_CALLS}/view/${record?.id}`);
          },
        })}
        pagination={{
          total: totalCount,
          current: variable?.skip,
          pageSize: variable?.limit,
          pageSizeOptions: PAGE_SIZE_OPTIONS,
        }}
      />
    </>
  );
}

export default ConsultationCallsList;
