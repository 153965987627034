import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import EditJudgement from './EditJudgement';
import JudgementsList from './JudgementList';
import './judgements.less';

const JudgementWrapper = () => (
  <Routes>
    <Route path={ROUTES.MAIN} exact element={<JudgementsList />} />
    <Route path={ROUTES.EDIT} exact element={<EditJudgement />} />
  </Routes>
);
export default JudgementWrapper;
