import { gql } from '@apollo/client';

export const EDIT_JUDGE_DETAILS = gql`
  mutation UpdateJudgeAdmin(
    $data: UpdateJudgeInput!
    $where: JudgeIdWhereUniqueInput!
  ) {
    updateJudgeAdmin(data: $data, where: $where) {
      data {
        _id
        id
        name
        slug
        type
        description
        category
        court
        noSpaceName
        mergedNoSpaceNames
        isVisible
        createdAt
        updatedAt
      }
      message
    }
  }
`;

export const CREATE_JUDGE = gql`
  mutation CreateJudgeAdmin($data: CreateJudgeInput!) {
    createJudgeAdmin(data: $data) {
      _id
      id
      name
      slug
      type
      description
      category
      court
      noSpaceName
      mergedNoSpaceNames
      isVisible
      createdAt
      updatedAt
    }
  }
`;

export const DELETE_JUDGE = gql`
  mutation DeleteJudgeAdmin($where: JudgeIdWhereUniqueInput!) {
    deleteJudgeAdmin(where: $where) {
      message
    }
  }
`;
