import { InfoCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import {
  Badge,
  Button,
  Checkbox,
  Radio,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import {
  LIMIT,
  MODULES,
  PAGE_SIZE_OPTIONS,
  ROLE_KEYS,
  ROUTES,
  SORT,
  SORT_OPTION,
  TEMPLATE_STATUS_COLOR,
  USER_ROLES_FILTERS,
  USER_TYPE_LABEL,
  defaultDateFormat,
} from '../../common/constants';
import CommonSelect from '../../components/CommonSelect';
import SearchComponent from '../../components/SearchComponent';
import useRouter from '../../hooks/useRouter';
import { LIST_USER_QUERY } from './graphql/queries';
import './user.less';

const { Title } = Typography;
const STATUS_FILTER = [
  {
    text: 'Active',
    value: true,
  },
  {
    text: 'Inactive',
    value: false,
  },
];

function UserList() {
  const { navigate } = useRouter();
  const [userList, setUserList] = useState();
  const [loading, setLoading] = useState(false);
  const [createdFiltered, setCreatedFiltered] = useState(false);
  const [userParams, setUserParams] = useState({
    filter: {
      limit: LIMIT,
      roles: [],
      search: '',
      skip: 0,
    },
    sort: [
      {
        sortOn: 'createdAt',
        sortBy: SORT_OPTION.DESC,
      },
    ],
  });

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: LIMIT,
      pageSizeOptions: PAGE_SIZE_OPTIONS,
    },
  });

  const [fetchUser] = useLazyQuery(LIST_USER_QUERY);

  function fetchUserCall(filterValue = userParams, pageNumber = 1) {
    setLoading(true);
    fetchUser({
      variables: filterValue,
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        setUserList(res?.listUsers?.user);
        const count = res?.listUsers?.count;
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: count,
            current: pageNumber,
          },
        });
        setLoading(false);
      },
      onError: () => {
        setLoading(false);
      },
    });
  }

  useEffect(() => {
    fetchUserCall();
  }, []);

  const handleTableChange = (pagination, filters, sorter, extra) => {
    let prepareFilter;
    if (extra.action === 'filter') {
      prepareFilter = {
        ...userParams,
        filter: {
          ...userParams.filter,
          roles: filters.roles,
          skip: 0,
          isActive:
            filters?.isActive && filters?.isActive?.length
              ? filters?.isActive?.[0]
              : undefined,
        },
      };
      fetchUserCall(prepareFilter);
    } else {
      prepareFilter = {
        ...userParams,
        filter: {
          ...userParams.filter,
          skip: (pagination?.current - 1) * LIMIT,
        },
        sort: [
          {
            sortOn: sorter.columnKey || 'createdAt',
            sortBy:
              sorter.order === SORT.ASC ? SORT_OPTION.ASC : SORT_OPTION.DESC,
          },
        ],
      };
      fetchUserCall(prepareFilter, pagination?.current);
    }
    setUserParams(prepareFilter);
  };

  const handleSearchChange = (e) => {
    const prepareFilter = {
      ...userParams,
      filter: { ...userParams.filter, search: e.trim() },
    };
    setUserParams(prepareFilter);
    fetchUserCall(prepareFilter);
  };

  const getTypeFilterProps = () => ({
    filterMultiple: true,
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div className="custom-filter-dropdown">
        {USER_ROLES_FILTERS?.map((option) => (
          <div key={option?.value} className="mt-6 mb-6">
            <Checkbox
              checked={selectedKeys.includes(option?.value)}
              onChange={(e) => {
                const updatedKeys = e.target.checked
                  ? [...selectedKeys, option.value]
                  : selectedKeys.filter((key) => key !== option.value);
                setSelectedKeys(updatedKeys);
              }}
            >
              {option?.label}
            </Checkbox>
          </div>
        ))}
        <div className="control-panel">
          <Button
            type="primary"
            size="small"
            onClick={() => {
              confirm();
            }}
            className="ok-button"
          >
            Apply
          </Button>
          <Button
            className="ml-8 reset-button"
            size="small"
            onClick={() => {
              setSelectedKeys([]);
              confirm();
            }}
          >
            Reset
          </Button>
        </div>
      </div>
    ),
    onFilter: (value, record) => record?.roles?.includes(value),
  });

  const getTypeStatusFilterProps = () => ({
    filterMultiple: false,
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div className="custom-filter-dropdown">
        {STATUS_FILTER?.map((option) => (
          <div key={option?.text} className="mt-6 mb-6">
            <Radio
              value={option.value}
              checked={selectedKeys.includes(option?.value)}
              onChange={(e) => {
                const updatedKeys = e.target.checked ? [option?.value] : [];
                setSelectedKeys(updatedKeys);
              }}
            >
              {option?.text}
            </Radio>
          </div>
        ))}
        <div className="control-panel">
          <Button
            type="primary"
            size="small"
            onClick={() => {
              confirm();
            }}
            className="ok-button"
          >
            Apply
          </Button>
          <Button
            className="ml-8 reset-button"
            size="small"
            onClick={() => {
              setSelectedKeys([]);
              confirm();
            }}
          >
            Reset
          </Button>
        </div>
      </div>
    ),
  });

  const handleUser = (value, confirm) => {
    const prepareFilter = {
      ...userParams,
      filter: { ...userParams.filter, createdBy: value },
    };
    setUserParams(prepareFilter);
    fetchUserCall(prepareFilter);
    setCreatedFiltered(!!value);
    confirm();
  };

  const getProviderFilterProps = () => ({
    filterMultiple: false,
    filterDropdown: ({ confirm }) => (
      <div className="custom-filter-dropdown">
        <div>
          <CommonSelect
            placeholder="Select Name"
            className="full-width"
            showSearch
            allowClear
            variant="borderless"
            onChange={(value) => {
              handleUser(value, confirm);
            }}
            query={LIST_USER_QUERY}
            fetchPolicy="network-only"
            responsePath="listUsers.user"
            valuePath="id"
            labelPath="firstName"
            optionalLabelPath="lastName"
            variables={{
              filter: {
                roles: [ROLE_KEYS.SUPER_ADMIN, ROLE_KEYS.ADMIN],
              },
              sort: [
                {
                  sortOn: 'createdAt',
                  sortBy: SORT_OPTION.DESC,
                },
              ],
            }}
          />
        </div>
      </div>
    ),
    onFilter: (value, record) => record?.category?.includes(value),
    filtered: createdFiltered,
  });

  return (
    <>
      <Title className="site-page-header p-0 mb-8 mt-0" level={3}>
        {MODULES.USER}{' '}
        {tableParams.pagination.total
          ? ` (${tableParams?.pagination?.total})`
          : ''}
      </Title>
      <div className="filter-input mb-12">
        <SearchComponent className="list-search" getData={handleSearchChange} />
        <Button
          className="ml-8"
          icon={<PlusCircleOutlined />}
          key="1"
          type="primary"
          onClick={() => {
            navigate(ROUTES?.ADD_USERS);
          }}
        >
          Add user
        </Button>
      </div>
      <Table
        columns={[
          {
            title: 'Name',
            sorter: true,
            render: (record) => (
              <>
                {`${record?.firstName} ${record?.lastName}`}
                {record?.migratedFrom ? (
                  /* static color is added in badge due to single time use in whole project */
                  <span>
                    {' '}
                    <Badge color="#108ee9" count={record?.migratedFrom} />
                  </span>
                ) : (
                  ''
                )}
              </>
            ),
            width: '20%',
            key: 'firstName',
          },
          {
            title: 'Email',
            render: (value) => (
              <div className="temp-email-block">
                <span>{value?.email}</span>
                {value?.tempEmail && (
                  <Tooltip title={`Verification pending : ${value?.tempEmail}`}>
                    <InfoCircleOutlined />
                  </Tooltip>
                )}
              </div>
            ),
          },
          {
            title: 'Phone Number',
            dataIndex: 'phoneNumber',
          },
          {
            title: 'City',
            render: (item) => <>{item?.city ? item?.city : '-'}</>,
          },
          {
            title: 'Profession',
            render: (item) => <>{item?.profession ? item?.profession : '-'}</>,
          },
          {
            title: 'Role',
            key: 'roles',
            render: (item) => <>{USER_TYPE_LABEL[item?.roles[0]]}</>,
            width: '20%',
            ...getTypeFilterProps(),
          },
          {
            title: 'Created by (Role)',
            render: (value) =>
              value?.userCreator?.firstName
                ? `${value?.userCreator?.firstName} ${
                    value?.userCreator?.lastName
                  } (${USER_TYPE_LABEL[value?.userCreator?.roles?.[0]] ?? '-'})`
                : '-',
            key: 'creator',
            ...getProviderFilterProps(),
          },
          {
            title: 'Created On',
            render: (item) => (
              <>{dayjs(item?.createdAt).format(defaultDateFormat)}</>
            ),
          },
          {
            title: 'Status',
            render: (item) =>
              item?.isActive ? (
                <Badge count="Active" color={TEMPLATE_STATUS_COLOR.PUBLISHED} />
              ) : (
                <Badge
                  count="Inactive"
                  color={TEMPLATE_STATUS_COLOR.UNPUBLISHED}
                />
              ),
            key: 'isActive',
            ...getTypeStatusFilterProps(),
          },
        ]}
        // eslint-disable-next-line arrow-body-style
        onRow={(record) => {
          return {
            onClick: () => {
              navigate(`${ROUTES?.USERS}/edit/${record?.id}`);
            },
          };
        }}
        rowKey={(record) => record?.id}
        dataSource={userList}
        pagination={tableParams?.pagination}
        loading={loading}
        scroll={{ y: `calc(100vh - 342px)` }}
        onChange={handleTableChange}
      />
    </>
  );
}

export default UserList;
