import { useLazyQuery } from '@apollo/client';
import { Select } from 'antd';
import { debounce, get, map, trim, uniqBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { LIMIT } from '../common/constants';

const { Option } = Select;
let searchDebounce = null;

const CommonSelect = (props) => {
  const {
    placeholder = 'Search',
    query,
    fetchPolicy = 'network-only',
    variables = {},
    responsePath,
    valuePath,
    labelPath,
    optionalLabelPath = '',
    showSearch,
    conditionToCheckBeforeQuery = true,
    isDataDependent = [],
    customOptions = [],
    useEffectDeps = [],
    inputRef = null,
    provider = null,
    ...rest
  } = props;
  const [listData, setListData] = useState([...customOptions] || []);
  const [hasMore, setHasMore] = useState(true);
  const [skipData, setSkipData] = useState(0);
  const [searchText, setSearchText] = useState('');

  const [fetchListData, { loading }] = useLazyQuery(query, {
    fetchPolicy,
    onCompleted: (res) => {
      const data = get(res, responsePath, []);
      const count = data?.length;
      setListData([...listData, ...data]);
      setHasMore(count >= LIMIT);
    },
    onError() {},
  });

  useEffect(() => {
    if (conditionToCheckBeforeQuery) {
      setListData([]);
    }
  }, isDataDependent);

  useEffect(() => {
    if (conditionToCheckBeforeQuery) {
      fetchListData({
        variables: {
          ...variables,
          filter: {
            ...(variables?.filter || {}),
            skip: 0,
            limit: LIMIT,
          },
        },
      });
    }
  }, useEffectDeps);

  const searchQuery = (search) => {
    if (!trim(search)) {
      return;
    }
    setSearchText(search);
    fetchListData({
      variables: {
        ...variables,
        filter: {
          ...(variables?.filter || {}),
          skip: skipData,
          limit: LIMIT,
          search,
        },
      },
    });
  };

  const handleSearch = (value) => {
    setSkipData(0);
    if (searchDebounce) {
      searchDebounce.cancel();
      searchDebounce = null;
    }
    searchDebounce = debounce(searchQuery, 500);
    searchDebounce(value);
  };

  const handleClear = () => {
    if (searchText) {
      searchQuery();
    }
  };
  const handleScroll = async (event) => {
    const { target } = event;
    const { scrollTop, clientHeight, scrollHeight } = target || {};
    const scrolledToBottom = scrollTop + clientHeight >= scrollHeight - 5;
    if (scrolledToBottom && hasMore) {
      const newSkip = skipData + LIMIT;
      await fetchListData({
        variables: {
          ...variables,
          filter: {
            ...variables?.filter,
            skip: newSkip,
            limit: LIMIT,
          },
        },
      });
      setSkipData(newSkip);
    }
  };

  return (
    <>
      <Select
        ref={inputRef}
        placeholder={placeholder}
        loading={loading}
        showSearch={showSearch}
        onSearch={showSearch && handleSearch}
        onClear={handleClear}
        onBlur={handleClear}
        onPopupScroll={handleScroll}
        filterOption={(inputValue, option) =>
          option?.children?.toLowerCase()?.includes(inputValue?.toLowerCase())
        }
        {...rest}
      >
        <>
          {map(
            uniqBy(
              provider ? [provider, ...listData] : [...listData],
              valuePath,
            ),
            (item) => {
              const optionValue = get(item, valuePath);
              const optionLabel = get(item, labelPath);
              const optionalLabel = get(item, optionalLabelPath);
              return (
                <Option
                  key={`option-key:${optionValue}`}
                  value={optionValue}
                  label={optionLabel}
                >
                  {optionalLabel
                    ? `${optionLabel} ${optionalLabel}`
                    : `${optionLabel}`}
                </Option>
              );
            },
          )}
        </>
      </Select>
    </>
  );
};

export default CommonSelect;
