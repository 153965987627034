/* eslint-disable no-underscore-dangle */
import {
  Card,
  Checkbox,
  Col,
  DatePicker,
  List,
  Row,
  Select,
  Spin,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { mongoClient } from '../../apollo';
import { AppContext } from '../../AppContext';
import { DATE_FORMAT, USER_TYPE } from '../../common/constants';
import BenchListSelect from '../../components/BenchesListSelect';
import { LIST_JUDGEMENTS } from '../judgements/graphql/queries';
import AppealDetails from './components/AppealDetails';
import PDFViewer from './components/PDFViewer';
import './review-appeals.less';

const { Title } = Typography;
const { RangePicker } = DatePicker;
const JUDGEMENT_LIMIT = 50;

function ReviewAppeals() {
  const containerRef = useRef(null);
  const { getCurrentUser, getCurrentUserRole } = useContext(AppContext);
  const { userBenches } = getCurrentUser() || {};
  const role = getCurrentUserRole() || {};
  const [initLoading, setInitLoading] = useState(true);
  const [judgements, setJudgements] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [selectedAppeal, setSelectedAppeal] = useState(null);
  const [assessmentYear, setAssessmentYear] = useState(null);
  const [formData, setFormData] = useState(null);
  const [loadingJudgementDetails, setLoadingJudgementDetails] = useState(false);
  const [withOutPancard, setWithOutPancard] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [selectedBench, setSelectedBench] = useState(null);
  const [dataLoading, setDataLoading] = useState(false);

  const listJudges = async ({ variables }) => {
    await mongoClient
      ?.query({
        query: LIST_JUDGEMENTS,
        variables,
      })
      .then((res) => {
        setInitLoading(false);
        setLoading(false);
        setJudgements(judgements.concat(res?.data?.judgmentsAdmin?.data));
      });
    setDataLoading(false);
  };

  useEffect(() => {
    listJudges({
      variables: {
        filter: {
          skip: JUDGEMENT_LIMIT * (currentPage - 1),
          limit: JUDGEMENT_LIMIT,
          startDate: assessmentYear
            ? dayjs(assessmentYear?.[0]).format(DATE_FORMAT)
            : null,
          endDate: assessmentYear
            ? dayjs(assessmentYear?.[1]).format(DATE_FORMAT)
            : null,
          withOutPancard,
          bench: selectedBench,
        },
      },
    });
  }, [currentPage, assessmentYear, withOutPancard, selectedBench]);

  const onLoadMore = () => {
    setLoading(true);
    setCurrentPage((prev) => prev + 1);
  };

  const handlePancardCheckbox = (value) => {
    setDataLoading(true);
    setSelectedAppeal(null);
    setFormData(null);
    setWithOutPancard(value.target.checked);
  };

  useEffect(() => {
    const handleScroll = () => {
      const container = containerRef?.current;
      if (container) {
        const bottom =
          container.scrollHeight ===
          container.scrollTop + container.clientHeight;
        if (bottom && hasMore && !loading) {
          onLoadMore();
        }
      }
    };

    const container = containerRef?.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
      return () => container.removeEventListener('scroll', handleScroll);
    }
  }, [hasMore, loading]);

  const handleStaffBench = (value) => {
    setDataLoading(true);
    setSelectedAppeal(null);
    setFormData(null);
    setSelectedBench(value);
  };

  const handleAdminBench = (selectedValue) => {
    setDataLoading(true);
    setSelectedAppeal(null);
    setFormData(null);
    setSelectedBench(selectedValue?.value);
  };

  return (
    <>
      <Title className="site-page-header p-0 mb-8 mt-0" level={3}>
        Review Appeals
      </Title>
      <div className="filter-input mb-12 d-flex flex-wrap">
        <div className="d-flex gap-12">
          <div className="d-flex gap-12 align-center review-appeals-filters">
            <span className="ml-8">Assessment Year</span>
            <RangePicker
              allowClear
              onChange={(value) => {
                setDataLoading(true);
                setJudgements([]);
                setSelectedAppeal(null);
                setFormData(null);
                setAssessmentYear(value);
              }}
              value={assessmentYear}
            />
          </div>
        </div>
        <div className="d-flex gap-12 align-center flex-wrap">
          <div className="review-appeals-filters">
            <Checkbox
              onChange={handlePancardCheckbox}
              checked={withOutPancard}
            />
            <span className="ml-8">Without Pancard</span>
          </div>

          {role === USER_TYPE.STAFF && (
            <Select
              placeholder="Select Bench"
              onChange={handleStaffBench}
              value={selectedBench}
              className="role-select-in-calls ml-8"
              options={userBenches?.map((e) => ({
                label: e.benchSlug,
                key: e.benchKey,
                value: e.benchId,
              }))}
            />
          )}
          {role === (USER_TYPE.ADMIN || USER_TYPE.SUPER_ADMIN) && (
            <BenchListSelect
              onChange={handleAdminBench}
              values={selectedBench}
            />
          )}
        </div>
      </div>
      <Row className="review-appeal-card-wrapper">
        <Col xs={24} sm={24} md={12} lg={7}>
          <Card className="full-height review-appeal-card">
            <div className="review-appeal-header">
              <span className="review-appeal-header-text">Appeal List</span>
            </div>
            <div className="review-appeal-content " ref={containerRef}>
              {initLoading || dataLoading ? (
                <div className="review-appeal-loader">
                  <Spin size="large" />
                </div>
              ) : (
                <>
                  <List
                    dataSource={judgements}
                    renderItem={(item) => (
                      <List.Item
                        className={`review-appeal-content-list-item ${
                          selectedAppeal === item?._id
                            ? 'review-appeal-content-list-item-selected'
                            : null
                        }`}
                        onClick={() => {
                          setLoadingJudgementDetails(true);
                          setSelectedAppeal(item?._id);
                        }}
                      >
                        {item?.appellant}
                      </List.Item>
                    )}
                  />
                  {loading && (
                    <div className="review-appeal-content-list-spin">
                      <Spin />
                    </div>
                  )}
                </>
              )}
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8}>
          <>
            <Card
              className={`full-height review-appeal-card ${
                loadingJudgementDetails ? null : 'review-appeal-card-loading'
              }`}
            >
              <div className="review-appeal-header">
                <span className="review-appeal-header-text">
                  Appeal Details
                </span>
              </div>
              <div className="review-appeal-content mt-8">
                <div className="review-appeal-loader">
                  <Spin size="large" />
                </div>
              </div>
            </Card>
          </>
          <>
            <Card
              className={`full-height review-appeal-card ${
                loadingJudgementDetails ? 'review-appeal-card-loading' : null
              }`}
            >
              <div className="review-appeal-header">
                <span className="review-appeal-header-text">
                  Appeal Details
                </span>
              </div>
              <div className="review-appeal-content mt-8">
                {selectedAppeal && (
                  <>
                    <AppealDetails
                      key={selectedAppeal}
                      id={selectedAppeal}
                      getFormData={(val) => {
                        setFormData(val);
                        setLoadingJudgementDetails(false);
                      }}
                      getJudgementLoadingValues={(val) =>
                        setLoadingJudgementDetails(val)
                      }
                    />
                  </>
                )}
              </div>
            </Card>
          </>
        </Col>
        <Col xs={24} sm={24} md={24} lg={9}>
          <Card className="full-height review-appeal-card-pdf">
            {/* eslint-disable-next-line no-nested-ternary */}
            {loadingJudgementDetails ? (
              <div className="review-appeal-loader-pdf">
                <Spin size="large" />
              </div>
            ) : (
              formData?.pdfUrl && (
                <PDFViewer fileUrl={formData?.pdfUrl ?? null} />
              )
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
}
export default ReviewAppeals;
