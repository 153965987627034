import { ExclamationCircleFilled, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Modal, Radio, Table, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { mongoClient } from '../../apollo';
import {
  COURT_LIST,
  JUDGES_TYPE,
  LIMIT,
  MODULES,
  PAGE_SIZE_OPTIONS,
  ROUTES,
  TAX_CATEGORIES,
} from '../../common/constants';
import SearchComponent from '../../components/SearchComponent';
import useRouter from '../../hooks/useRouter';
import { DELETE_JUDGE } from './graphql/mutations';
import { LIST_JUDGES } from './graphql/queries';

const { Title } = Typography;
const { confirm: deleteConfirm } = Modal;

function JudgesList() {
  const { navigate } = useRouter();
  const [filter, setFilter] = useState({
    limit: LIMIT,
    skip: 1,
    search: '',
    isVisible: null,
  });
  const [sort, setSort] = useState({ sortBy: 'DESC', sortOn: 'createdAt' });
  const [judgesData, setJudgesData] = useState([]);
  const [loading, setLoading] = useState(true);

  const listJudges = async ({ variables }) => {
    await mongoClient
      ?.query({
        query: LIST_JUDGES,
        fetchPolicy: 'network-only',
        variables,
      })
      .then((res) => {
        setJudgesData(res?.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    const { skip, limit, search } = filter;
    const prepareFilterValue = {
      skip: (skip - 1) * limit,
      limit,
      search,
      category: filter?.category,
      court: filter?.court,
    };
    if (filter?.isVisible) {
      prepareFilterValue.isVisible = filter?.isVisible;
    }
    listJudges({
      variables: {
        filter: prepareFilterValue,
        sort,
      },
    });
  }, [filter, sort]);

  const deleteCall = async (id) => {
    const { skip, limit, search } = filter;
    setLoading(true);
    await mongoClient
      ?.mutate({
        mutation: DELETE_JUDGE,
        variables: {
          where: {
            id,
          },
        },
      })
      .then(() => {
        const prepareFilterValue = {
          skip: (skip - 1) * limit,
          limit,
          search,
          category: filter?.category,
          court: filter?.court,
        };
        if (filter?.isVisible) {
          prepareFilterValue.isVisible = filter?.isVisible;
        }
        listJudges({
          variables: {
            filter: prepareFilterValue,
            sort,
          },
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setFilter({
      ...filter,
      category: filters?.category?.[0],
      court: filters?.court?.[0],
      skip: pagination?.current,
      limit: pagination?.pageSize,
      isVisible:
        filters?.isVisible && filters?.isVisible?.length
          ? filters?.isVisible?.[0]
          : null,
    });
    setSort({
      sortOn: sorter?.order ? sorter?.field : 'createdAt',
      sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC',
    });
  };

  const handleSearchChange = (value) => {
    setFilter({ ...filter, skip: 1, search: value });
  };

  const sortOrder = sort.sortBy === 'DESC' ? 'descend' : 'ascend';

  const handleApplyFilters = (confirm) => {
    confirm();
  };

  const filterPopup = () => ({
    filterMultiple: false,
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div className="custom-filter-dropdown">
        {Object?.keys(TAX_CATEGORIES)?.map((category) => (
          <div key={category}>
            <Radio
              checked={selectedKeys.includes(category)}
              onChange={(e) => {
                const updatedKeys = e.target.checked ? [category] : [];
                setSelectedKeys(updatedKeys);
              }}
            >
              {TAX_CATEGORIES[category]}
            </Radio>
          </div>
        ))}
        <div className="control-panel">
          <Button
            type="primary"
            size="small"
            onClick={() => handleApplyFilters(confirm)}
            className="ok-button"
          >
            Apply
          </Button>
          <Button
            className="ml-8 reset-button"
            size="small"
            onClick={() => {
              setSelectedKeys([]);
              confirm();
            }}
          >
            Reset
          </Button>
        </div>
      </div>
    ),
    onFilter: (value, record) => record?.category?.includes(value),
  });

  const filterCourtPopup = () => ({
    filterMultiple: false,
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div className="custom-filter-dropdown">
        {Object?.keys(COURT_LIST)?.map((court) => (
          <div key={court}>
            <Radio
              checked={selectedKeys.includes(court)}
              onChange={(e) => {
                const updatedKeys = e.target.checked ? [court] : [];
                setSelectedKeys(updatedKeys);
              }}
            >
              {COURT_LIST[court]}
            </Radio>
          </div>
        ))}
        <div className="control-panel">
          <Button
            type="primary"
            size="small"
            onClick={() => confirm()}
            className="ok-button"
          >
            Apply
          </Button>
          <Button
            className="ml-8 reset-button"
            size="small"
            onClick={() => {
              setSelectedKeys([]);
              confirm();
            }}
          >
            Reset
          </Button>
        </div>
      </div>
    ),
  });

  const handleDelete = (id) => {
    deleteConfirm({
      centered: true,
      title: 'Are you sure to delete?',
      icon: <ExclamationCircleFilled />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteCall(id);
      },
      onCancel() {},
    });
  };

  const columns = [
    {
      title: 'Name',
      sorter: true,
      sortOrder: sort.sortOn === 'name' ? sortOrder : null,
      dataIndex: 'name',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      render(description) {
        return description ?? '-';
      },
    },

    {
      title: 'Categories',
      dataIndex: 'category',
      render(category) {
        return category?.map((item) => TAX_CATEGORIES[item]).join(', ');
      },
      ...filterPopup(),
    },
    {
      title: 'Court',
      dataIndex: 'court',
      render(court) {
        return court?.map((item) => item).join(', ');
      },
      ellipsis: true,
      ...filterCourtPopup(),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      className: 'judge-type',
      render(type) {
        return JUDGES_TYPE[type];
      },
    },
    {
      title: 'Is Visible',
      dataIndex: 'isVisible',
      filterMultiple: false,
      render(value) {
        return value ? 'True' : 'False';
      },
      key: 'isVisible',
      filters: [
        {
          text: 'Visible',
          value: true,
        },
        {
          text: 'Not Visible',
          value: false,
        },
      ],
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'action',
      render: (_, { _id: id } = {}) => (
        <div className="category-action">
          <Link to={`${ROUTES.EDIT_JUDGE}${id}`}>Edit</Link>
          <Button
            type="link"
            className="action-btn"
            onClick={() => handleDelete(id)}
            danger
          >
            Delete
          </Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <Title className="site-page-header p-0 mb-8 mt-0" level={3}>
        {MODULES.JUDGES}
        {judgesData?.judgesAdmin?.count
          ? ` (${judgesData?.judgesAdmin?.count})`
          : ''}
      </Title>
      <div className="filter-input mb-12">
        <SearchComponent className="list-search" getData={handleSearchChange} />
        <Button
          icon={<PlusCircleOutlined />}
          className="ml-8"
          key="1"
          type="primary"
          onClick={() => {
            navigate(ROUTES?.ADD_JUDGE);
          }}
        >
          Add Judge
        </Button>
      </div>
      <Table
        columns={columns}
        rowKey={(record) => record?.id}
        dataSource={judgesData?.judgesAdmin?.data}
        pagination={{
          total: judgesData?.judgesAdmin?.count,
          current: filter.skip,
          pageSize: filter.limit,
          pageSizeOptions: PAGE_SIZE_OPTIONS,
        }}
        loading={loading}
        scroll={{ y: `calc(100vh - 342px)` }}
        onChange={handleTableChange}
      />
    </>
  );
}

export default JudgesList;
