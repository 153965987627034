import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Space,
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import dayjs from 'dayjs';
import React, { useContext, useMemo, useState } from 'react';
import { AppContext } from '../../../AppContext';
import { mongoClient } from '../../../apollo';
import {
  LIMIT,
  ROLE_KEYS,
  TAX_CATEGORIES,
  defaultDateFormat,
} from '../../../common/constants';
import DebounceSelectComponent from '../../../components/DebounceSelectComponent';
import LoaderComponent from '../../../components/LoaderComponent';
import useRouter from '../../../hooks/useRouter';
import { LIST_ASSESSEESS } from '../../assesses/graphql/queries';
import { LIST_JUDGES } from '../../judges/graphql/queries';
import KeywordsModal from '../../keywords/components/KeywordsModal';
import SectionsModal from '../../sections/components/SectionModal';
import { GET_KEYWORD, GET_LAWYERS, GET_SECTIONS } from '../graphql/queries';
import AddJudgeModal from './AddJudgeModal';
import AssesseeDetails from './AssesseeDetails';

const SELECT_FIELDS = {
  JUDGES: 'judges',
  SECTIONS: 'sections',
  ASSESSES: 'assesses',
  KEYWORDS: 'keywords',
  DEPARTMENT_LAWYERS: 'departmentLawyers',
  ASSESSES_LAWYERS: 'assesseeLawyers',
};
function JudgementForm({
  form,
  onFinish,
  loading,
  initialValues = null,
  loadingJudgementDetails = true,
  styling = { sm: 8, md: 8, lg: 8 },
  hideAddButtons = true,
}) {
  const assesseStyling = { sm: 24, md: 24, lg: 24 };
  const {
    state: { currentUser },
  } = useContext(AppContext);
  const isStaff = useMemo(() => currentUser?.roles.includes(ROLE_KEYS.STAFF), [
    currentUser?.roles,
  ]);
  const { navigate } = useRouter();
  const commonInitialVariable = {
    filter: {
      skip: 0,
      limit: LIMIT,
      search: '',
    },
    sort: { sortBy: 'DESC', sortOn: 'createdAt' },
  };
  const [judgeVariable, setJudgeVariable] = useState(commonInitialVariable);
  const [assesseeVariable, setAssesseeVariable] = useState(
    commonInitialVariable,
  );

  const [assesseeLawyersVariable, setAssesseeLawyersVariable] = useState(
    commonInitialVariable,
  );
  const [departmentLawyersVariable, setDepartmentLawyersVariable] = useState(
    commonInitialVariable,
  );
  const [keywordVariable, setKeywordVariable] = useState(commonInitialVariable);
  const [sectionVariable, setSectionVariable] = useState(commonInitialVariable);
  const [keywordFormPopup, setKeywordFormPopup] = useState({
    isOpen: false,
    id: 'new',
  });
  const [sectionFormPopup, setSectionFormPopup] = useState({
    isOpen: false,
    id: 'new',
  });
  const [openAddJudgeModal, setOpenAddJudgeModal] = useState(false);

  const [ends, setEnds] = useState({
    isAssessesLawyerEnd: false,
    isDepartmentLawyerEnd: false,
    isKeywordEnd: false,
    isSectionsEnd: false,
    isJudgesEnd: false,
    isAssessesEnd: false,
  });

  const [options, setOptions] = useState({
    assesseeLawyers: [],
    departmentLawyers: [],
    keyword: [],
    sections: [],
    judges: [],
    assesses: [],
  });

  const listJudges = async (variables = judgeVariable, isScroll = false) => {
    try {
      const listData = await mongoClient?.query({
        query: LIST_JUDGES,
        fetchPolicy: 'network-only',
        variables: {
          ...variables,
          filter: {
            ...variables.filter,
            skip: isScroll ? options.judges.length : 0,
          },
        },
      });
      let prepareOptions = [];
      const count = listData?.data?.judgesAdmin.data.length;
      if (count) {
        prepareOptions = listData?.data?.judgesAdmin.data.map((item) => ({
          label: item.name,
          // eslint-disable-next-line no-underscore-dangle
          value: item._id,
        }));
      }
      setEnds({ ...ends, isJudgesEnd: count < LIMIT });
      setOptions({
        ...options,
        judges: isScroll
          ? [...options.judges, ...prepareOptions]
          : prepareOptions,
      });
      setJudgeVariable(variables);
      return prepareOptions;
      // eslint-disable-next-line no-empty
    } catch (error) {}
  };

  const listAssessees = async (
    variables = assesseeVariable,
    isScroll = false,
  ) => {
    try {
      const listData = await mongoClient?.query({
        query: LIST_ASSESSEESS,
        fetchPolicy: 'network-only',
        variables: {
          ...variables,
          filter: {
            ...variables.filter,
            skip: isScroll ? options.assesses.length : 0,
          },
        },
      });
      let prepareOptions = [];
      const count = listData?.data?.assesseesAdmin.data.length;
      if (count) {
        prepareOptions = listData?.data?.assesseesAdmin.data.map((item) => ({
          label: item.name,
          // eslint-disable-next-line no-underscore-dangle
          value: item._id,
        }));
      }
      setEnds({ ...ends, isAssessesEnd: count < LIMIT });
      setOptions({
        ...options,
        assesses: isScroll
          ? [...options.assesses, ...prepareOptions]
          : prepareOptions,
      });
      setAssesseeVariable(variables);
      return prepareOptions;
      // eslint-disable-next-line no-empty
    } catch (error) {}
  };

  const listSections = async (
    variables = sectionVariable,
    isScroll = false,
  ) => {
    try {
      const listData = await mongoClient?.query({
        query: GET_SECTIONS,
        fetchPolicy: 'network-only',
        variables: {
          ...variables,
          filter: {
            ...variables.filter,
            skip: isScroll ? options.sections.length : 0,
          },
        },
      });
      let prepareOptions = [];
      const count = listData?.data?.sectionsAdmin?.data?.length;
      // eslint-disable-next-line no-debugger
      if (count) {
        prepareOptions = listData?.data?.sectionsAdmin?.data?.map((item) => ({
          label: item?.label,
          // eslint-disable-next-line no-underscore-dangle
          value: item?._id,
        }));
      }
      setEnds({ ...ends, isSectionsEnd: count < LIMIT });
      setOptions({
        ...options,
        sections: isScroll
          ? [...options.sections, ...prepareOptions]
          : prepareOptions,
      });
      setSectionVariable(variables);
      return prepareOptions;
      // eslint-disable-next-line no-empty
    } catch (error) {}
  };

  const listKeywords = async (
    variables = keywordVariable,
    isScroll = false,
  ) => {
    try {
      const listData = await mongoClient?.query({
        query: GET_KEYWORD,
        fetchPolicy: 'network-only',
        variables: {
          ...variables,
          filter: {
            ...variables.filter,
            skip: isScroll ? options.keyword.length : 0,
          },
        },
      });
      let prepareOptions = [];
      const count = listData?.data?.keywordsAdmin?.data?.length;
      if (count) {
        prepareOptions = listData?.data?.keywordsAdmin?.data?.map((item) => ({
          label: item?.label,
          // eslint-disable-next-line no-underscore-dangle
          value: item?._id,
        }));
      }
      setEnds({ ...ends, isKeywordEnd: count < LIMIT });
      setOptions({
        ...options,
        keyword: isScroll
          ? [...options.keyword, ...prepareOptions]
          : prepareOptions,
      });
      setKeywordVariable(variables);
      return prepareOptions;
      // eslint-disable-next-line no-empty
    } catch (error) {}
  };

  const listLawyers = async (
    variables = commonInitialVariable,
    isScroll = false,
    isListAssesseesLawyer = false,
  ) => {
    try {
      let skipValue = isScroll ? options.departmentLawyers.length : 0;
      if (isListAssesseesLawyer) {
        skipValue = isScroll ? options.assesseeLawyers.length : 0;
      }
      const listData = await mongoClient?.query({
        query: GET_LAWYERS,
        fetchPolicy: 'network-only',
        variables: {
          ...variables,
          filter: {
            ...variables.filter,
            skip: skipValue,
          },
        },
      });
      let prepareOptions = [];
      const count = listData?.data?.lawyersAdmin?.data?.length;
      if (count) {
        prepareOptions = listData?.data?.lawyersAdmin?.data?.map((item) => ({
          label: item?.name,
          // eslint-disable-next-line no-underscore-dangle
          value: item?._id,
        }));
      }
      if (isListAssesseesLawyer) {
        setEnds({ ...ends, isAssessesLawyerEnd: count < LIMIT });
        setOptions({
          ...options,
          assesseeLawyers: isScroll
            ? [...options.assesseeLawyers, ...prepareOptions]
            : prepareOptions,
        });
        setAssesseeLawyersVariable(variables);
      } else {
        setEnds({ ...ends, isDepartmentLawyerEnd: count < LIMIT });
        setOptions({
          ...options,
          departmentLawyers: isScroll
            ? [...options.departmentLawyers, ...prepareOptions]
            : prepareOptions,
        });
        setDepartmentLawyersVariable(variables);
      }
      return prepareOptions;
      // eslint-disable-next-line no-empty
    } catch (error) {}
  };

  const handleScroll = async (event, fieldName) => {
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};
    const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
    if (scrolledToBottom) {
      switch (fieldName) {
        case SELECT_FIELDS.JUDGES:
          if (!ends.isJudgesEnd) {
            return listJudges(judgeVariable, true);
          }
          break;
        case SELECT_FIELDS.ASSESSES:
          if (!ends.isAssessesEnd) {
            return listAssessees(judgeVariable, true);
          }
          break;
        case SELECT_FIELDS.ASSESSES_LAWYERS:
          if (!ends.isAssessesLawyerEnd) {
            return listLawyers(assesseeLawyersVariable, true, true);
          }
          break;
        case SELECT_FIELDS.DEPARTMENT_LAWYERS:
          if (!ends.isDepartmentLawyerEnd) {
            return listLawyers(departmentLawyersVariable, true);
          }
          break;
        case SELECT_FIELDS.KEYWORDS:
          if (!ends.isKeywordEnd) {
            return listKeywords(keywordVariable, true);
          }
          break;
        case SELECT_FIELDS.SECTIONS:
          if (!ends.isSectionsEnd) {
            return listSections(sectionVariable, true);
          }
          break;
        default:
          break;
      }
    }
  };
  if (loadingJudgementDetails) return <LoaderComponent />;

  const handleKeywordClose = () => {
    setKeywordFormPopup({
      isOpen: false,
      id: null,
    });
  };

  const handleSectionClose = () => {
    setSectionFormPopup({
      isOpen: false,
      id: null,
    });
  };

  const initialJudgementFormValues = {
    ...initialValues,
    addToNewsLetter: Boolean(initialValues?.addToNewsLetter),
    dateOfPronouncement: initialValues?.dateOfPronouncement
      ? dayjs(initialValues?.dateOfPronouncement)
      : null,
    appealFiledOn:
      initialValues?.appealFiledOn && dayjs(initialValues?.appealFiledOn),
    dateOfFirstHearing:
      initialValues?.dateOfFirstHearing &&
      dayjs(initialValues?.dateOfFirstHearing),
    dateOfLastHearing:
      initialValues?.dateOfLastHearing &&
      dayjs(initialValues?.dateOfLastHearing),
    dateOfNextHearing:
      initialValues?.dateOfNextHearing &&
      dayjs(initialValues?.dateOfNextHearing),
    dateOfFinalHearing:
      initialValues?.dateOfFinalHearing &&
      dayjs(initialValues?.dateOfFinalHearing),
    dateOfTribunalOrder:
      initialValues?.dateOfTribunalOrder &&
      dayjs(initialValues?.dateOfTribunalOrder),
    dateOfOrder:
      initialValues?.dateOfOrder && dayjs(initialValues?.dateOfOrder),
    assessee: initialValues?.assessee
      ? {
          // eslint-disable-next-line no-underscore-dangle
          value: initialValues?.assessee?._id,
          label: initialValues?.assessee?.name,
        }
      : null,
    judges: initialValues?.judges?.map((item) => ({
      // eslint-disable-next-line no-underscore-dangle
      value: item?._id,
      label: item?.name,
    })),
    keywords: initialValues?.keywords?.map((item) => ({
      // eslint-disable-next-line no-underscore-dangle
      value: item?._id,
      label: item?.label,
    })),
    sections: initialValues?.sections?.map((item) => ({
      // eslint-disable-next-line no-underscore-dangle
      value: item?._id,
      label: item?.label,
    })),
    assesseeLawyers: initialValues?.assesseeLawyers?.map((item) => ({
      // eslint-disable-next-line no-underscore-dangle
      value: item?._id,
      label: item?.name,
    })),
    departmentLawyers: initialValues?.departmentLawyers?.map((item) => ({
      // eslint-disable-next-line no-underscore-dangle
      value: item?._id,
      label: item?.name,
    })),
  };

  return (
    <>
      <KeywordsModal
        formPopup={keywordFormPopup}
        handleCloseModal={handleKeywordClose}
        handleModalForm={handleKeywordClose}
      />
      <SectionsModal
        formPopup={sectionFormPopup}
        handleCloseModal={handleSectionClose}
        handleModalForm={handleSectionClose}
      />
      <AddJudgeModal
        openAddJudgeModal={openAddJudgeModal}
        setOpenAddJudgeModal={setOpenAddJudgeModal}
      />

      <div className="card-body-wrapper">
        <Form
          form={form}
          name="register"
          onFinish={onFinish}
          layout="vertical"
          initialValues={initialJudgementFormValues}
          disabled={hideAddButtons ? isStaff : false}
        >
          <Row gutter={16}>
            <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item
                name="appealNumber"
                label="Appeal No."
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: 'Invalid Number',
                  },
                ]}
              >
                <Input placeholder="Enter Appeal No." />
              </Form.Item>
            </Col>
            <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item
                name="appealRSAnumber"
                label="Appeal RSA No."
                rules={[
                  {
                    whitespace: true,
                  },
                ]}
              >
                <Input placeholder="Enter Appeal RSA No." />
              </Form.Item>
            </Col>
            <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item
                name="category"
                label="Category"
                className="user-type-input"
                rules={[
                  {
                    required: true,
                    message: 'Please select a Category',
                  },
                ]}
              >
                <Select placeholder="Select Type">
                  {Object.entries(TAX_CATEGORIES).map(([value, label]) => (
                    <Select.Option key={value} value={value}>
                      {label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item
                name="appealType"
                label="Appeal Type"
                rules={[
                  {
                    whitespace: true,
                  },
                ]}
              >
                <Input placeholder="Enter Appeal Type" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item name="bench" label="Bench" className="user-type-input">
                <Select placeholder="Select Bench" disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item
                label="Appeal Filled By"
                name="appealFiledBy"
                rules={[
                  {
                    whitespace: true,
                    message: 'Please enter who filed Appeal',
                  },
                ]}
              >
                <Input placeholder="Appeal Filled By" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item
                name="appellant"
                label="Appellant"
                rules={[
                  {
                    whitespace: true,
                    message: 'Please enter Appellant',
                  },
                ]}
              >
                <Input placeholder="Enter Appellant" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item name="court" label="Court">
                <Input placeholder="Enter Court" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item
                name="respondent"
                label="Respondent"
                rules={[
                  {
                    whitespace: true,
                    message: 'Please enter Respondent',
                  },
                ]}
              >
                <Input placeholder="Enter Respondent" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item
                name="detailsURL"
                label="Details URL"
                rules={[
                  {
                    whitespace: true,
                    message: 'Please enter Details URL',
                  },
                ]}
              >
                <Input placeholder="Enter Details URL" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item
                name="assessmentYear"
                label="Assessment Year"
                rules={[
                  {
                    whitespace: true,
                    message: 'Please enter Assessment Year',
                  },
                ]}
              >
                <Input placeholder="Enter Assessment Year" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item
                name="dateOfPronouncement"
                label="Date of Pronouncement"
              >
                <DatePicker format={defaultDateFormat} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item
                name="dateOfFirstHearing"
                label="Date of First Hearing"
              >
                <DatePicker format={defaultDateFormat} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item name="dateOfLastHearing" label="Date of Last Hearing">
                <DatePicker format={defaultDateFormat} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item name="dateOfNextHearing" label="Date of Next Hearing">
                <DatePicker format={defaultDateFormat} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item
                name="dateOfFinalHearing"
                label="Date of Final Hearing"
              >
                <DatePicker format={defaultDateFormat} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item
                name="dateOfTribunalOrder"
                label="Date of Tribunal Hearing"
              >
                <DatePicker format={defaultDateFormat} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item name="dateOfOrder" label="Date of Order">
                <DatePicker format={defaultDateFormat} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item name="appealFiledOn" label="Appeal filled on">
                <DatePicker format={defaultDateFormat} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item
                name="benchAllotted"
                label="Bench allotted"
                rules={[
                  {
                    whitespace: true,
                    message: 'Please enter Allotted Bench',
                  },
                ]}
              >
                <Input placeholder="Enter Allotted Bench" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item
                name="caseStatus"
                label="Case Status"
                rules={[
                  {
                    whitespace: true,
                    message: 'Please enter Case Status',
                  },
                ]}
              >
                <Input placeholder="Enter Case Status" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item
                name="orderResult"
                label="Order Result"
                rules={[
                  {
                    whitespace: true,
                    message: 'Please enter Order Result',
                  },
                ]}
              >
                <Input placeholder="Enter Order Result" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item name="pdfUrl" label="PDF URL">
                <Input disabled placeholder="Enter PDF URL" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item name="processPdfStatus" label="Process PDF Status">
                <Input disabled placeholder="Enter Process PDF Status" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item name="errorMessage" label="Error Message">
                <Input disabled placeholder="Error Message" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item name="tags" label="Tags">
                <Input placeholder="Enter Tags" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item
                name="assessee"
                label="Assessee"
                className="user-type-input"
              >
                <DebounceSelectComponent
                  onPopupScroll={(e) => handleScroll(e, SELECT_FIELDS.ASSESSES)}
                  placeholder="Select Assessee"
                  onSearch={(e) =>
                    listAssessees(
                      {
                        ...assesseeVariable,
                        filter: {
                          ...assesseeVariable.filter,
                          search: e,
                          skip: 0,
                        },
                      },
                      false,
                    )
                  }
                  onFocus={() => !options?.assesses?.length && listAssessees()}
                  options={options?.assesses}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item
                name="judges"
                label={
                  <div className="judgement-label-text ">
                    <div>Judges</div>
                    {!hideAddButtons && (
                      <div
                        className="ml-16 judgement-tags"
                        onClick={() => setOpenAddJudgeModal(true)}
                      >
                        + Add Judges
                      </div>
                    )}
                  </div>
                }
                className="user-type-input judgment-label"
              >
                <DebounceSelectComponent
                  mode="multiple"
                  onPopupScroll={(e) => handleScroll(e, SELECT_FIELDS.JUDGES)}
                  placeholder="Select Judges"
                  onSearch={(e) =>
                    listJudges(
                      {
                        ...judgeVariable,
                        filter: { ...judgeVariable.filter, search: e, skip: 0 },
                      },
                      false,
                    )
                  }
                  onFocus={() => !options?.judges?.length && listJudges()}
                  options={options.judges}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item
                name="keywords"
                label={
                  <div className="judgement-label-text ">
                    <div>Keywords</div>
                    {!hideAddButtons && (
                      <div
                        className="ml-16 judgement-tags"
                        onClick={() =>
                          setKeywordFormPopup({ isOpen: true, id: 'new' })
                        }
                      >
                        + Add Keywords
                      </div>
                    )}
                  </div>
                }
                className="user-type-input judgment-label"
              >
                <DebounceSelectComponent
                  mode="multiple"
                  onPopupScroll={(e) => handleScroll(e, SELECT_FIELDS.KEYWORDS)}
                  placeholder="Select Keywords"
                  onSearch={(e) =>
                    listKeywords(
                      {
                        ...keywordVariable,
                        filter: {
                          ...keywordVariable.filter,
                          search: e,
                          skip: 0,
                        },
                      },
                      false,
                    )
                  }
                  onFocus={() => !options?.keyword?.length && listKeywords()}
                  options={options?.keyword}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item
                name="sections"
                label={
                  <div className="judgement-label-text ">
                    <div>Sections</div>
                    {!hideAddButtons && (
                      <div
                        className="ml-16 judgement-tags"
                        onClick={() =>
                          setSectionFormPopup({ isOpen: true, id: 'new' })
                        }
                      >
                        + Add Sections
                      </div>
                    )}
                  </div>
                }
                className="user-type-input judgment-label"
              >
                <DebounceSelectComponent
                  mode="multiple"
                  onPopupScroll={(e) => handleScroll(e, SELECT_FIELDS.SECTIONS)}
                  placeholder="Select Sections"
                  onSearch={(e) =>
                    listSections(
                      {
                        ...sectionVariable,
                        filter: {
                          ...sectionVariable.filter,
                          search: e,
                          skip: 0,
                        },
                      },
                      false,
                    )
                  }
                  options={options?.sections}
                  onFocus={() => !options?.sections?.length && listSections()}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item
                name="assesseeLawyers"
                label="Assessee Lawyers"
                className="user-type-input"
              >
                <DebounceSelectComponent
                  mode="multiple"
                  onPopupScroll={(e) =>
                    handleScroll(e, SELECT_FIELDS.ASSESSES_LAWYERS)
                  }
                  placeholder="Select Assessee Lawyers"
                  onSearch={(e) =>
                    listLawyers(
                      {
                        ...assesseeVariable,
                        filter: {
                          ...assesseeVariable.filter,
                          search: e,
                          skip: 0,
                        },
                      },
                      false,
                      true,
                    )
                  }
                  options={options?.assesseeLawyers}
                  onFocus={() =>
                    !options?.assesseeLawyers?.length &&
                    listLawyers(commonInitialVariable, false, true)
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item
                name="departmentLawyers"
                label="Department Lawyers"
                className="user-type-input"
              >
                <DebounceSelectComponent
                  mode="multiple"
                  onPopupScroll={(e) =>
                    handleScroll(e, SELECT_FIELDS.DEPARTMENT_LAWYERS)
                  }
                  placeholder="Select Department Lawyers"
                  onSearch={(e) =>
                    listLawyers(
                      {
                        ...departmentLawyersVariable,
                        filter: {
                          ...departmentLawyersVariable.filter,
                          search: e,
                          skip: 0,
                        },
                      },
                      false,
                    )
                  }
                  options={options?.departmentLawyers}
                  onFocus={() =>
                    !options?.departmentLawyers?.length && listLawyers()
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item name="resultInFavourOf" label="Result In Favour Of">
                <Input placeholder="Result in Favour Of" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item
                name="yearOfPronouncement"
                label="Year Of Pronouncement"
              >
                <Input placeholder="Year of Pronouncement" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item name="durationOfJustice" label="Duration Of Justice">
                <Input placeholder="Duration of Justice" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item name="slug" label="Slug">
                <Input disabled placeholder="Slug" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item name="notes" label="Notes">
                <Input placeholder="Notes" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item name="downloadCounter" label="Download Counter">
                <Input type="number" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item name="createdAt" label="Created At">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item name="updatedAt" label="Updated At">
                <Input type="number" disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Form.Item
                name="judgmentText"
                label="Judgment Text"
                rules={[
                  {
                    whitespace: true,
                    message: 'Please enter Judgment Text',
                  },
                ]}
              >
                <TextArea
                  type="text"
                  placeholder="Enter Judgment Text"
                  rows={7}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12}>
              <Form.Item name="addToNewsLetter" valuePropName="checked">
                <Checkbox>Add to Newsletter</Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Space size={8}>
              <Button
                onClick={() =>
                  hideAddButtons
                    ? navigate(-1)
                    : form.setFieldsValue(initialJudgementFormValues)
                }
              >
                Cancel
              </Button>
              <Button type="primary" htmlType="submit" loading={loading}>
                Save
              </Button>
            </Space>
          </Form.Item>
        </Form>
        {!loading && !hideAddButtons && (
          <div>
            <div className="review-appeal-content-assessee">
              <div className="review-appeal-assessee-title">
                Assessee Details
              </div>
              <div>
                <AssesseeDetails
                  initialValues={initialValues}
                  styling={assesseStyling}
                  loadingJudgementDetails={loading}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default JudgementForm;
