import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const EDIT_JUDGEMENT_DETAILS = gql`
  mutation UpdateJudgementAdmin(
    $data: UpdateJudgementInput!
    $where: JudgementIdWhereUniqueInput!
  ) {
    updateJudgementAdmin(data: $data, where: $where) {
      message
      data {
        _id
        appealNumber
        appellant
        category
        court
        judgmentText
        respondent
        pdfUrl
        scrapePdfUrl
        dateOfOrder
        metaData
        downloadCounter
        createdAt
        updatedAt
      }
    }
  }
`;

export const CREATE_JUDGE = gql`
  mutation CreateJudgeAdmin($data: CreateJudgeInput!) {
    createJudgeAdmin(data: $data) {
      _id
      name
      slug
      type
      description
      category
      court
      isVisible
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_EXPERT_SUMMARY = gql`
  mutation adminUpdateExpertSummary(
    $data: AdminUpdateExpertSummaryInput!
    $where: ExpertSummaryWhereUnique!
  ) {
    adminUpdateExpertSummary(data: $data, where: $where) {
      data {
        id
      }
      message
    }
  }
`;
