import React from 'react';
import EditJudgement from '../../judgements/EditJudgement';
import '../review-appeals.less';

function AppealDetails({ id, getFormData, getJudgementLoadingValues }) {
  return (
    <EditJudgement
      isReviewAppeal
      appealId={id}
      key={id}
      getFormData={getFormData}
      getJudgementLoadingValues={getJudgementLoadingValues}
    />
  );
}
export default AppealDetails;
