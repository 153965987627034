import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const EDIT_ASSESSEE_DETAILS = gql`
  mutation UpdateAssesseeAdmin(
    $data: UpdateAssesseeInput!
    $where: AssesseeIdWhereUniqueInput!
  ) {
    updateAssesseeAdmin(data: $data, where: $where) {
      data {
        _id
        name
        slug
        type
        pancard
        initialOfSurnameOrTitle
        category
        court
        createdAt
        updatedAt
      }
    }
  }
`;

export const CREATE_ASSESSEE = gql`
  mutation CreateAssesseeAdmin($data: CreateAssesseeInput!) {
    createAssesseeAdmin(data: $data) {
      _id
      name
      slug
      type
      pancard
      initialOfSurnameOrTitle
      category
      court
      createdAt
      updatedAt
    }
  }
`;

export const DELETE_ASSESSEE = gql`
  mutation DeleteAssesseeAdmin($where: AssesseeIdWhereUniqueInput!) {
    deleteAssesseeAdmin(where: $where) {
      message
    }
  }
`;
