import { Modal } from 'antd';
import React from 'react';
import CreateJudge from '../../judges/CreateJudge';

const AddJudgeModal = ({ openAddJudgeModal, setOpenAddJudgeModal }) => {
  const styling = { sm: 12, md: 12, lg: 12 };
  return (
    <Modal
      destroyOnClose
      title="Add Judge"
      open={openAddJudgeModal}
      onCancel={() => setOpenAddJudgeModal(false)}
      footer={null}
    >
      <CreateJudge
        isModalView
        styling={styling}
        handleCancel={() => setOpenAddJudgeModal(false)}
      />
    </Modal>
  );
};

export default AddJudgeModal;
