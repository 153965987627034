import { gql } from '@apollo/client';

export const CONSULTATION_CALLS = gql`
  query consultationCallsAdmin(
    $sort: [ListConsultationCallSort!]
    $filter: ListConsultationFilterAdmin
  ) {
    consultationCallsAdmin(sort: $sort, filter: $filter) {
      count
      data {
        id
        title
        description
        type
        link
        price
        duration
        buffer
        slug
        status
        audienceSize
        endDate
        creator {
          email
          firstName
          id
          companyName
          profileImage
          profession
          phoneNumber
          lastName
          city
          roles
        }
        isFeatured
        view
        bookedCount
        createdAt
        updatedAt
        bookingWindow
        bookingWindowUnit
        useDefaultAvailability
        dateRangeType
        dateRangeStartDate
        dateRangeEndDate
        dateCount
        sessionStartDate
        sessionCount
        recurringSessionDays
        upcomingSessionDates
        calendarEventId
        guestUserData
        workspaceData
      }
    }
  }
`;

export const GET_CONSULTATION_CALL_DETAILS = gql`
  query consultationCallAdmin($where: ConsultationCallWhereUniqueInput) {
    consultationCallAdmin(where: $where) {
      id
      title
      description
      type
      price
      duration
      calendarEventId
      workspaceData
      guestUserData
      slug
      view
      isFeatured
      bookingWindow
      bookingWindowUnit
      createdAt
      category
      showOnWeb {
        JUDGEMENT {
          INDIRECT_TAX {
            visible
          }
          DIRECT_TAX {
            visible
          }
          COMPANY_LAW {
            visible
          }
        }
        HOME {
          INDIRECT_TAX {
            visible
          }
          DIRECT_TAX {
            visible
          }
          COMPANY_LAW {
            visible
          }
        }
        BLOG {
          INDIRECT_TAX {
            visible
          }
          DIRECT_TAX {
            visible
          }
          COMPANY_LAW {
            visible
          }
        }
      }
      bookedCount
      creator {
        email
        firstName
        id
        companyName
        profileImage
        profession
        phoneNumber
        lastName
        city
        roles
        totalBookings
      }
    }
  }
`;

export const COMPLETED_CONSULTATION_CALLS = gql`
  query BookingOrders(
    $filter: ListBookingOrdersFilter!
    $sort: [ListBookingOrdersSort!]
  ) {
    bookingOrders(filter: $filter, sort: $sort) {
      count
      data {
        id
        bookedFrom
        callId
        purchaser {
          firstName
          lastName
        }
        consultationCall {
          type
        }
        createdAt
        startTime
        endTime
        url
      }
    }
  }
`;
